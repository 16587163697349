<template>
    <v-container class="pa-2">
        <v-row>
            <v-col cols="2">
                <v-label>Rendimiento por:</v-label>
            </v-col>
            <v-col cols="10">
                <v-select dense solo outlined flat hide-details :items="tipo_rendimiento" item-text="text" item-value="value"
                    v-model="tipo_rendimiento_seleccionado" />
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="2">
                <v-label v-if="tipo_rendimiento_seleccionado === 'rendimiento_participante'">Participante:</v-label>
                <!-- <v-label v-if="tipo_rendimiento_seleccionado === 'rendimiento_grupo'">Grupos:</v-label> 
                <v-label v-if="tipo_rendimiento_seleccionado === 'rendimiento_curso'">Cursos:</v-label> -->
            </v-col>
            <v-col cols="10">
                <v-autocomplete v-if="tipo_rendimiento_seleccionado === 'rendimiento_participante'"
                    dense solo outlined flat  hide-details clearable 
                    v-model="participante_seleccionado" 
                    :items="participantes" 
                    item-key="id" 
                    item-text="full_nombre" 
                    item-value="rut" 
                    prepend-inner-icon="search"
                    @change="getRendimientoParticipante"
                />
                <!-- <v-autocomplete v-if="tipo_rendimiento_seleccionado === 'rendimiento_grupo'"
                    dense solo outlined flat  hide-details clearable 
                    v-model="id_grupo_curso_seleccionado" 
                    :items="grupos_curso" 
                    item-key="id" 
                    item-text="nombre" 
                    item-value="id" 
                    prepend-inner-icon="search" 
                />
                <v-autocomplete v-if="tipo_rendimiento_seleccionado === 'rendimiento_curso'"
                    dense solo outlined flat  hide-details clearable 
                    v-model="curso_seleccionado" 
                    :items="cursos" 
                    item-key="id" 
                    item-text="nombre" 
                    item-value="id" 
                    prepend-inner-icon="search" 
                />  -->
            </v-col>
        </v-row>
        <div v-if="tipo_rendimiento_seleccionado === 'rendimiento_curso'">
            <v-row>
                <v-col cols="2">
                    <v-label>Grupo Acceso:</v-label> 
                </v-col>
                <v-col cols="10">
                    <v-autocomplete
                        dense solo outlined flat  hide-details clearable 
                        v-model="id_grupo_curso_seleccionado" 
                        :items="grupos_curso" 
                        item-key="id" 
                        item-text="nombre" 
                        item-value="id" 
                        prepend-inner-icon="search" 
                    />
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="2">
                    <v-label>Curso del grupo:</v-label>
                </v-col>
                <v-col cols="10">
                    <v-autocomplete
                        dense solo outlined flat  hide-details clearable 
                        v-model="curso_seleccionado" 
                        :items="cursos" 
                        item-key="id" 
                        item-text="nombre" 
                        item-value="id" 
                        prepend-inner-icon="search" 
                    /> 
                </v-col>
            </v-row>
        </div>
        <v-row justify="end">
            <v-col cols="auto" class="mr-0 pr-0" v-if="isData">
                <v-btn color="primary" class="mr-1" @click="generateReportePDF">
                    <v-icon left>picture_as_pdf</v-icon>
                    reporte pdf
                </v-btn>
            </v-col>
        </v-row>

    </v-container>
</template>
<script>
import { axiosHelper } from '@/helpers'
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import logo from '@/assets/logo.jpg'; // logo para incluir en los reportes
export default{
    props: {
        activeSubTabCursosInternos:{
            type: Number
        },
        isData: {
            type: Boolean,  
            default: false, 
        }
    },
    data:()=>({
        //
        tipo_rendimiento:[
            { text: 'participante', value: 'rendimiento_participante' },
            // { text: 'grupo', value: 'rendimiento_grupo' },
            { text: 'curso ', value: 'rendimiento_curso' } 
        ],
        //
        data:{},
        //
        tipo_rendimiento_seleccionado:null,
        //
        participantes:[],
        participante_seleccionado:null,
        //
        grupos_curso:[],
        id_grupo_curso_seleccionado:null,
        //
        cursos: [],
        curso_seleccionado: null,
    }),
    created(){
        this.tipo_rendimiento_seleccionado='rendimiento_participante';
        this.fetchGrupoCursos();
    },
    mounted() {
        this.getParticipantes();
    },
    watch:{
        id_grupo_curso_seleccionado(newGrupoCursoId) {
            console.log("alo alo alo alo alo");
            this.$emit('filter-changed');
            if (newGrupoCursoId) {
                this.fetchCursosPorGrupo(newGrupoCursoId);
            }
        },
        curso_seleccionado(newCursoId){
            console.log('newCursoId: ', newCursoId);
            if (newCursoId){
                this.getRendimientoCurso();
            }
            // else{
            //     console.log("alo alo alo alo alo");
            //     this.$emit('filter-changed', data);
            // }
        }
    },
    methods:{
        async fetchCursosPorGrupo(grupoCursoId) {
            try {
                console.log("obteniendo los cursos" + grupoCursoId)
                const response = await axiosHelper.get(`edu/cursos/nombres/?grupo_curso_id=${grupoCursoId}`);
                if (response.data.length === 1) {
                    this.cursos = response.data;
                    this.curso_seleccionado = this.cursos[0].id;

                } else {
                    this.cursos = [{ id: null, nombre: 'Seleccione...' }, ...response.data];
                    this.curso_seleccionado = null;
                }
            } catch (error) {
                console.error("Error fetching courses:", error);
            }
        },
        async fetchGrupoCursos() {
            try {
                const response = await axiosHelper.get('edu/grupos/nombres');
                this.grupos_curso = [{ id: null, nombre: 'Seleccione ...' }, ...response.data];
                this.id_grupo_curso_seleccionado = null;

            } catch (error) {
                console.error("Error fetching group names:", error);
            }
        },
        async getParticipantes(){
            try {
                const response = await axiosHelper.get('edu/usuarios/participantes');
                this.participantes = [{ id: null, full_nombre: 'Seleccione un participante...' }, ...response.data];
                this.participante_seleccionado = null;
            } catch (error) {
                console.error("Error al obtener los participantes:", error);
            }
        },
        async getRendimientoCurso(){
            try{
                const params = {
                    'grupo_id':this.id_grupo_curso_seleccionado,
                    'curso_id':this.curso_seleccionado
                }
                const response = await axiosHelper.post('edu/stats/type/?d=rendimiento_curso', params);
                /**
                 * response.data es una arreglo de evaluaciones
                 * y cada objeto evaluacion contiene a su vez un arreglo de preguntas de la evaluacion
                 */
                const data = {
                    'tipo_data':'rendimiento_curso',
                    'data':response.data
                }
                this.data = data;
                this.$emit('datos-recibidos', data);
            }catch(error){
                console.error("Error al obtener el rendimiento del curso:", error);
            }
        },
        async getRendimientoParticipante(rut){
            try{
                const params ={rut}
                console.log("obteniendo el rendimiento del participante " + rut);
                const response = await axiosHelper.post('edu/stats/type/?d=persona', params);
                let dataFormatted = [];
                const data_persona = response.data.persona;
                let dataAux =response.data.rendimiento.logs;
                for(let grupo of dataAux){ // por cada objeto de logs
                    for(let registro of grupo.registros){
                        const evaluacion = {
                            id_evaluacion:registro.evaluacion.id,
                            nombre:registro.evaluacion.nombre,
                            nombre_curso:registro.evaluacion.curso
                        };
                        const respuestas = [];
                        for(let respuesta of registro.respuestas){
                            respuestas.push(respuesta);
                        }
                        evaluacion.respuestas = respuestas;
                        dataFormatted.push(evaluacion);
                    }
                }
                const data = {
                    'tipo_data':'rendimiento_persona',
                    'data_persona':data_persona,
                    'data':dataFormatted
                }
                this.data = data;
                this.$emit('datos-recibidos', data);
            }catch(error){
                console.error("Error al obtener el rendimiento del participante:", error);
            }
        },
        generateReportePDF(){
            const anchoLinea = { maxWidth: 160 };
            const data = this.data.data;
            const tipo_data = this.data.tipo_data;
            console.log("generateReportePDF: ", data);
            const doc = new jsPDF();
            const margin = 20;
            const headerHeight = 5;
            const fechaActual = new Date();
            const pageWidth = doc.internal.pageSize.getWidth();
            const pageHeight = doc.internal.pageSize.getHeight();
            const addHeader = () => {
                const pageCount = doc.internal.getNumberOfPages();
                for (let i = 1; i <= pageCount; i++) {
                    doc.setPage(i);
                    doc.addImage(logo, 'PNG', margin + 150, margin / 2, 20, 10); // Ajusta la posición según el margen
                }
            };
            const addFooter = () => {
                const pageCount = doc.internal.getNumberOfPages();
                for (let i = 1; i <= pageCount; i++) {
                    doc.setPage(i);
                    doc.setFontSize(10);
                    doc.setFont('helvetica', 'normal');
                    const text = `${fechaActual} Página ${i} de ${pageCount}`;
                    const textWidth = doc.getTextWidth(text);
                    doc.text(text, pageWidth - margin - textWidth, pageHeight - margin / 2);
                }
            };
            const startContentY = margin + headerHeight;
            doc.setFontSize(22);
            doc.setFont('helvetica', 'bold');
            const tituloY = startContentY; // Posición Y del título considerando el margen y el encabezado
            let titulo = "";
            if(tipo_data === 'rendimiento_curso'){
                titulo = doc.splitTextToSize("Reporte de rendimiento por curso" , pageWidth - margin * 2);
            }
            if(tipo_data === 'rendimiento_persona'){
                titulo = doc.splitTextToSize("Reporte de rendimiento por participante", pageWidth - margin * 2);
            }
            doc.text(titulo, margin, tituloY, anchoLinea);
            let startY = tituloY + (titulo.length * 10);
            // Subtítulo y descripción
            doc.setFontSize(12);
            if(tipo_data === 'rendimiento_curso'){
                doc.setFont('times', 'italic');
                const lines = doc.splitTextToSize("Este reporte proporciona un indicador del total de la cantidad de respuestas incorrectas para las evaluaciones del curso " + "'"+data[0].curso_nombre+"'", 160);
                doc.text(lines, margin, startY);
                startY += 6 * lines.length;
                doc.setLineWidth(0.1);
                doc.line(margin, startY, pageWidth - margin, startY); // Coordenadas (x1, y1, x2, y2)
                startY += 10;
            }
            if(tipo_data ==='rendimiento_persona'){
                //
                const desplazamientox=40;
                const lineHeight = 6; 
                const data_persona = this.data.data_persona;
                //
                doc.setFont("times", "bold");
                doc.text("Participante:", margin, startY);
                doc.setFont("times", "italic");
                doc.text(data_persona.first_name + ' ' + data_persona.last_name, margin + desplazamientox, startY,anchoLinea);
                startY +=6;
                //
                doc.setFont("times", "bold");
                doc.text("Rut:", margin, startY);
                doc.setFont("times", "italic");
                doc.text(data_persona.rut, margin + desplazamientox, startY,anchoLinea);
                startY +=6;
                //
                doc.setFont("times", "bold");
                doc.text("Cargo:", margin, startY);
                doc.setFont("times", "italic");
                doc.text(data_persona.gerencia.nombre, margin + desplazamientox, startY,anchoLinea);
                startY +=6;
                //
                doc.setFont("times", "bold");
                doc.text("Empresa:", margin, startY);
                doc.setFont("times", "italic");
                doc.text(data_persona.empresa.nombre, margin + desplazamientox, startY,anchoLinea);
                startY +=6;
                doc.text("Este reporte muestra informacion sobre el desempeño del participante en función del error cometido al seleccionar una respuesta para una pregunta dada.", margin,startY,anchoLinea);
                startY +=8;
                //
                doc.setLineWidth(0.1);
                doc.line(margin, startY, pageWidth - margin, startY); // Coordenadas (x1, y1, x2, y2)
                startY += 8;
                if(data.length > 0) {
                    data.forEach(item => {
                        const cursoNombre = doc.splitTextToSize(item.nombre.toLowerCase(), pageWidth - margin * 2);
                        doc.setFontSize(14);
                        doc.setFont('helvetica', 'bold');
                        const lines = doc.splitTextToSize("Evaluación: "+ cursoNombre, 160);
                        doc.text(lines, margin, startY);
                        startY += lineHeight * lines.length; 
                        doc.setFontSize(10);
                        doc.setFont('times', 'italic');
                        doc.text("Curso: "+ item.nombre_curso, margin, startY,anchoLinea);
                        if(item.respuestas.length > 0) {
                            startY += cursoNombre.length * 5;
                            const body = item.respuestas.map(respuesta => [
                                respuesta.pregunta__texto,
                                respuesta.incorrectas
                            ]);

                            doc.autoTable({
                                head: [['Pregunta', 'veces incorrectas']],
                                body: body,
                                startY: startY + 2,
                                margin: { top: startContentY, left: margin, right: margin },
                                didDrawPage: addHeader,
                                headStyles: {
                                    halign: 'center' // Alineación global para todos los encabezados (opcional)
                                },
                                columnStyles: {
                                    1: { halign: 'center' }
                                },
                            });
                            startY = doc.lastAutoTable.finalY + 10;
                        } else {
                            doc.setFont('times', 'normal');
                            doc.setFontSize(12);
                            doc.text("Sin resultados para este tipo de reportes", margin, startY + 5);
                            startY += 20;
                        }
                    });
                } else {
                    doc.setFont('times', 'normal');
                    doc.setFontSize(12);
                    doc.text("Participante no registra evaluaciones.", margin, startY + 5);
                    startY += 20;
                }
            }
            if(tipo_data ==='rendimiento_curso') {
                data.forEach(item => {
                    const cursoNombre = doc.splitTextToSize(item.nombre.toLowerCase(), pageWidth - margin * 2);
                    doc.setFontSize(14);
                    doc.setFont('helvetica', 'bold');
                    // doc.text("Evaluación: "+ cursoNombre, margin, startY ,anchoLinea);
                    const lines = doc.splitTextToSize("Evaluación: "+ cursoNombre, 160);
                    doc.text(lines, margin, startY);
                    startY += 6 * lines.length;
                    doc.setFontSize(10);
                    doc.setFont('times', 'italic');
                    doc.text("Curso: "+ item.curso_nombre, margin, startY);

                    if(item.preguntas.length > 0) {
                        startY += cursoNombre.length * 5;

                        const body = item.preguntas.map(preguntas => [
                            preguntas.texto,
                            preguntas.cantidad_incorrectas
                        ]);

                        doc.autoTable({
                            head: [['Pregunta', 'veces incorrectas']],
                            body: body,
                            startY: startY + 2,
                            margin: { top: startContentY, left: margin, right: margin },
                            didDrawPage: addHeader,
                            headStyles: {
                                halign: 'center' // Alineación global para todos los encabezados (opcional)
                            },
                            columnStyles: {
                                1: { halign: 'center' }
                            },
                        });

                        startY = doc.lastAutoTable.finalY + 10;
                    } else {
                        doc.setFont('times', 'normal');
                        doc.setFontSize(12);
                        doc.text("Sin resultados para este tipo de reportes", margin, startY + 5);
                        startY += 20;
                    }
                });
            }
            addHeader();
            addFooter();
            doc.save('rendimiento.pdf');
        }
    }

}
</script>