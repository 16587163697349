<template>
    <div>
        <div class="ma-2">
            <!-- este componente emite dos aventos al padre datos-recibidos y filter-changed.
            dihchos eventos activan los respectivos metodos del componente padre.
            -->
            <BuscadorReporteRendimiento :activeSubTabCursosInternos="activeSubTabCursosInternos" class="custom-background" @datos-recibidos="actualizarItems" @filter-changed="clearTable" :isData="isData"/>
        </div>
        <v-divider />
        <div v-if="data.tipo_data === 'rendimiento_persona'">
            <v-data-table :headers="headers" :items="data.data" :mobile-breakpoint="0" :items-per-page="10">
                <template #item.evaluaciones_participante="{ item }">
                    <span><strong>Evaluación:</strong> {{ item.nombre }}</span><br>
                    <span><strong>Curso:</strong> {{ item.nombre_curso }}</span>
                </template>
                <template #item.respuestas_participante="{ item }">
                    <v-data-table :headers="sub_headers" :items="item.respuestas" :mobile-breakpoint="0" :items-per-page="10000" hide-default-footer class="elevation-1">
                        <template #item.pregunta_evaluacion="{ item }">
                            <span>{{ item.pregunta__texto }}</span>
                        </template>
                        <template #item.incorrectas_participante="{ item }">
                            <span>{{ item.incorrectas }}</span>
                        </template>
                    </v-data-table>
                </template>
            </v-data-table>
        </div>
        <div v-if="data.tipo_data === 'rendimiento_curso'">
            <v-data-table :headers="headers" :items="data.data" :mobile-breakpoint="0" :items-per-page="10">
                <template #item.evaluaciones_participante="{ item }">
                    <span><strong>Evaluación:</strong> {{ item.nombre }}</span><br>
                </template>
                <template #item.respuestas_participante="{ item }">
                    <v-data-table :headers="sub_headers" :items="item.preguntas" :mobile-breakpoint="0" :items-per-page="10000" hide-default-footer class="elevation-1">
                        <template #item.pregunta_evaluacion="{ item }">
                            <span>{{ item.texto }}</span>
                        </template>
                        <template #item.incorrectas_participante="{ item }">
                            <span>{{ item.cantidad_incorrectas }}</span>
                        </template>
                    </v-data-table>
                </template>
            </v-data-table>
        </div>
    </div>
</template>
<script>
import moment from 'moment'
import 'moment/locale/es' // Importar el idioma español
import BuscadorReporteRendimiento from '@/shared/components/BuscadorReporteRendimiento'
import { axiosHelper } from '@/helpers'
moment.locale('es') // Establecer el idioma globalmente
export default{
    props:{
        activeSubTabCursosInternos:{
            type: Number,
        }
    },
    data(){
        return{
            headers: [
                { text: 'Evaluaciones', value: 'evaluaciones_participante' },
                { text: 'Respuestas', value: 'respuestas_participante', sortable: false },
            ],
            sub_headers: [
                { text: 'Preguntas', value: 'pregunta_evaluacion' },
                { text: 'veces incorrectas', value: 'incorrectas_participante', sortable: false,align: 'right' }
            ],
            data: {}, // data para desplegar en la tabla
            isData:false,
        }
        
    },
    components:{
        BuscadorReporteRendimiento
    },
    methods:{
        actualizarItems(nuevosItems) {
            this.data = nuevosItems;
            console.log("data de actualizarItems", this.data);
            // console.log("datos recibidos en la tabla: " ,this.items);
            if (Object.keys(nuevosItems).length === 0 && nuevosItems.constructor === Object) {
                this.isData = false;
            } else {
                this.isData = true;
            }
        },
        clearTable() {
            this.data = {};
            this.isData = false; // hace desaparecer los botones de descarga
        }
    }
}
</script>