<template>
    <div>
        <v-row>
            <v-col cols="12">
                <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                    <v-btn class="ma-0" large solo depressed color="primary" v-bind="attrs" v-on="on" @click="generar_reporte_masivo">
                        <v-icon class="mr-2">picture_as_pdf</v-icon>
                        <span class="subtitle-2">Estado cursos</span>
                    </v-btn>
                    </template>
                    <span>
                        Genera un reporte consolidado en pdf que incluye el ESTADO
                        ACTUAL de TODOS los cursos de la plataforma.(al {{ fechaActual }})
                    </span>
                </v-tooltip>
            </v-col>
        </v-row>
        <v-row class="mt-0">
            <v-col cols="12">
                <DashCard title="Reportes de cursos" title-icon="list" body-class="pa-0">
                    <v-tabs fixed-tabs v-model="activeTab">
                        <!-- <v-tab class="subtitle-2">Por participante</v-tab> -->
                        <v-tab class="subtitle-2">cursos internos</v-tab>
                        <v-tab class="subtitle-2">cursos externos</v-tab>
                        <!-- <v-tab-item>
                            <v-divider />
                            <UserTableReportes capacitaciones buscador :items="usuarios" @change="$parent.$parent.reload_dashboard()" />
                        </v-tab-item> -->
                        <v-tab-item>
                            <v-divider />
                            <v-tabs fixed-tabs v-model="activeSubTabCursosInternos">
                                <!-- <v-tooltip bottom>
                                    <template v-slot:activator="{ on, attrs }">
                                    <v-tab v-bind="attrs" v-on="on">Estado</v-tab>
                                    </template>
                                    <span class="custom-tooltip">
                                        Permite obtener información del estado de un curso en relación a sus participantes.
                                        Quienes han realizado un curso, quienes lo tienen pendiente etc.
                                    </span>
                                </v-tooltip>
                                <v-tooltip bottom>
                                    <template v-slot:activator="{ on, attrs }">
                                    <v-tab v-bind="attrs" v-on="on">Rendimiento</v-tab>
                                    </template>
                                    <span class="custom-tooltip">
                                        Permite obtener información sobre el rendimiento de un curso en relación al resultado de las
                                        evaluaciones asociadas a el.
                                    </span>
                                </v-tooltip> -->
                                <v-tab class="subtitle-2">Por Estado</v-tab>
                                <v-tab class="subtitle-2">Por Rendimiento</v-tab>

                                <v-tab-item>
                                    <TablaReporte :activeTab="activeTab" :activeSubTabCursosInternos="activeSubTabCursosInternos"/>
                                </v-tab-item>
                                <v-tab-item>
                                    <TablaReporteRendimiento :activeSubTabCursosInternos="activeSubTabCursosInternos"/>
                                </v-tab-item>
                            </v-tabs>
                        </v-tab-item>
                        <v-tab-item>
                            <v-divider />
                            <TablaReporte :activeTab="activeTab"/>
                        </v-tab-item>
                    </v-tabs>
                </DashCard>
            </v-col>
        </v-row>
        <DialogImportGMA ref="import_dialog" @success="$parent.$parent.reload_dashboard()" />
        <DownloadDialog ref="down_dialog" request-type="get" :raw-response="true"/>
        <Message ref="dialog" />
    </div>
</template>

<script>
import DialogImportGMA from '@/educap/dialogs/DialogImportGMA'
import DashCard from '@/shared/components/DashCard'
import Message from '@/shared/components/Message'
import UserTableReportes from '@/shared/components/UserTableReportes'
import TablaReporte from '@/shared/components/TablaReporte'
import TablaReporteRendimiento from '@/shared/components/TablaReporteRendimiento'
import DownloadDialog from '@/shared/components/DownloadDialog'
import { axiosHelper, messages } from '@/helpers'
import moment from 'moment';

export default {
    components: {
        DashCard,
        Message,
        UserTableReportes,
        DialogImportGMA,
        DownloadDialog,
        TablaReporte,
        TablaReporteRendimiento
    },
    data: () => ({
        activeTab: 0,
        activeSubTabCursosInternos:0,
        usuarios: []
    }),
    computed: {
        tabla_loading() {
            return !this.usuarios;
        },
        cap_evaluadores() {
            return this.usuarios.filter(x => x.groups.includes('cap_evaluador'));
        },
        cap_participantes() {
            return this.usuarios.filter(x => x.groups.includes('cap_participante'));
        },
        fechaActual() {
            return moment().format('LL'); // Formato 'LL' muestra la fecha como "15 de agosto de 2024"
        }
    },
    methods: {
        show_import_user() {
            this.$refs.import_dialog.show();
        },
        generar_reporte_masivo(){
            this.$refs.down_dialog.show(`edu/cursos-reportes`, null, `reporte-masivo.pdf`);
        }
    },
    mounted() {
        axiosHelper.get('edu/usuarios/')
            .then(response => {
                this.usuarios = response.data;
            })
        this.$parent.$parent.set_title("Reportes", "report");
    }
}
</script>
<style>
.custom-tooltip {
  width: 200px;  /* Ajusta el ancho */
  height: 150px; /* Ajusta el alto */
  display: flex;
  align-items: center; /* Opcional: centra el texto verticalmente */
  justify-content: center; /* Opcional: centra el texto horizontalmente */
  text-align: left; /* Opcional: centra el texto */
}
</style>